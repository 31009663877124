<template>
  <v-app id="inspire">
    <v-row>
      <v-col>
        <v-data-table
          v-if="activitySlices"
          :headers="activitySlices.keys"
          :items="activitySlices.items"
          @click:row="handleRowClick"
        ></v-data-table>
      </v-col>
    </v-row>
  </v-app>
</template>

<script>
// import Table from "../components/Table";
import { mapGetters } from "vuex";

export default {
  //
  computed: {
    ...mapGetters(["activitySlices"]),
  },
  beforeCreate() {
    this.$store.dispatch("clearSingleActivitySlice");
  },
  mounted() {
    this.$store.dispatch("clearSingleActivitySlice");
    this.$store.dispatch("getActivitySlices");
  },
  methods: {
    handleRowClick(e) {
      this.$router.push({
        path: `/activity-slice/${e.activitySliceId}`,
      });
    },
  },
};
</script>
